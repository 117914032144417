import { render, staticRenderFns } from "./download.vue?vue&type=template&id=6df18847&scoped=true&"
import script from "./download.vue?vue&type=script&lang=js&"
export * from "./download.vue?vue&type=script&lang=js&"
import style0 from "./download.vue?vue&type=style&index=0&id=6df18847&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6df18847",
  null
  
)

export default component.exports